.header {
  position: fixed;
  z-index: 1;
  top: 0px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  background-color: transparentize($color: rgb(183, 204, 242), $amount: 0.2);
  transition: all 1000ms;
  @include responsive(mobile) {
    background-color: transparentize($color: rgb(183, 204, 242), $amount: 0.5);
    height: 100px;
  }
  &:hover {
    background-color: transparentize($color: rgb(183, 204, 242), $amount: 0);
    transition: all 300ms;
  }

  &__logo {
    margin-top: 40px;
    margin-left: 50px;
    width: 200px;
    height: 75px;
    @include responsive(mobile) {
      width: 140px;
      height: 60px;
      margin-left: 15px;
      margin-top: 20px;
    }
  }
  &__nav {
    min-width: 400px;
    margin-right: 100px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    @include responsive(mobile) {
      min-width: 100px;
      flex-flow: column;
      justify-content: flex-start;
    }

    &--menu {
      display: flex;
      flex-flow: row;

      justify-content: flex-end;
      list-style: none;
      @include responsive(mobile) {
        flex-flow: column;
      }
    }
    & li {
      min-width: 100px;
      padding-top: 60px;
      line-height: 100px;
      text-align: right;
      @include responsive(mobile) {
        min-width: 50px;
        padding-top: 10px;
        line-height: 30px;
      }
    }
    & a {
      text-decoration: none;

      color: $secondColor;

      margin-left: 10px;
      margin-right: 10px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 20px;
      @include responsive(mobile) {
        font-size: 10px;
      }
    }
    & p {
      height: 50px;
    }
  }
  & li:hover {
    cursor: pointer;
  }
}
