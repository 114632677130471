.footer {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: $secondColor;
  height: 200px;
  padding-bottom: 50px;
  color: white;
  @include responsive(mobile) {
    height: 150px;
    padding-bottom: 0;
  }

  &__container {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    @include responsive(mobile) {
      width: 20%;
      height: 100px;
    }
  }

  &__logo {
    position: relative;
    left: 50px;
    width: 200px;
    @include responsive(mobile) {
      width: 100px;
      left: 15px;
    }
  }
  &__adress {
    width: 30%;
    display: flex;
    flex-flow: column;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 10px;
    margin-top: auto;
    margin-bottom: auto;
    border-left: 1px solid white;
    padding-left: 16px;
    @include responsive(mobile) {
      font-size: 8px;
      margin: 0;
      height: 130px;
      width: 35%;
      padding-top: 20px;
      margin-top: 10px;
    }
    & p {
      @include responsive(mobile) {
        margin-top: 5;
      }
    }
    &--contact {
      display: flex;
      color: white;
      flex-flow: row;
      & i {
        width: 50px;
        color: white;
        line-height: 30px;
        @include responsive(mobile) {
          width: 15%;
        }
      }
    }
    &--mail {
      width: 50px;
      line-height: 30px;
      color: white;
    }
  }
  &__network {
    width: 30%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    & a {
      color: white;
    }
    & :hover {
      cursor: pointer;
    }

    & i {
      width: 50%;
      padding-top: 10px;
      margin: 0 auto;
    }
    & p {
      font-family: "Montserrat";
      font-size: 20px;
      width: 50%;
      margin: 0 auto;
      @include responsive(mobile) {
        font-size: 8px;
      }
    }
  }
}
