.imageGallery {
  position: relative;
  width: 100%;
  min-height: 500px;
  background-image: url(/src/assets/IMG_8450.webp);
  background-size: cover;
  background-position-y: 65%;
  @include responsive(mobile) {
    min-height: 150px;
  }
  @include responsive(mobile) {
    width: 100%;
  }
}
