@mixin responsive($size: desktop) {
  @media screen and (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}
@mixin buttonradius($radius: 10px) {
  border-top-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
@keyframes imgslide {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
