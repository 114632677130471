@import "~@fortawesome/fontawesome-free/css/all.min.css";
* {
  box-sizing: border-box;
}

.App html,
body,
#root {
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0px;
  font-family: "Montserrat";
}
html {
  scroll-behavior: auto;
  @include responsive(mobile) {
    width: 100%;
    height: 100%;
  }
}
body {
  background-color: transparentize($color: rgb(23, 23, 72), $amount: 1);
  scroll-margin-top: 0;
}
.body {
  display: block;
  height: 100%;
  min-height: 100%;
  padding-bottom: 30px;
  @include responsive(mobile) {
    width: 100%;
    height: 100%;
  }
}
a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
.mainHome,
.mainProduct,
.mainContact {
  position: relative;
  top: 150px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  min-height: 100%;
  width: 100%;
  @include responsive(mobile) {
    top: 100px;
  }
}

.mainHome__text {
  background-image: url(/src/assets/Fond.webp);
  background-size: 100%;
  background-position-y: 0%;
  @include responsive(mobile) {
    background-size: 100%;
  }
}
