.article__wellfare {
  background-color: transparent;
  width: 70%;
  margin: 0 auto;
  & h2 {
    font-size: 30px;
    justify-content: center;
  }
  & p {
    font-family: "Montserrat";
    font-size: 14px;
    text-align: justify;
  }
  & br {
    margin-bottom: 10px;
  }
}
