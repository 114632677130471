.container__head {
  display: block;
  height: auto;
  min-height: 85%;
}

.avantage_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
}
.avantage {
  &-card {
    display: flex;
    flex-flow: column;
    width: 20%;
    height: 160px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    &_main {
      display: flex;
      flex-flow: column;

      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
  }
  & h2,
  h3 {
    text-align: center;
  }
  & h2 {
    @include responsive(mobile) {
      font-size: 16px;
    }
  }
  &-background-icone {
    display: flex;
    border-top: solid 1px $fifthColor;
    border-bottom: solid 1px $thirdColor;
    width: 60px;
    height: 60px;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    margin: 0 auto;
    align-items: center;
    transition: rotate 1s;
    @include responsive(mobile) {
      width: 30px;
      height: 30px;
    }
    &:hover {
      rotate: 180deg;
    }
    &:hover img,
    :focus {
      rotate: -180deg;
    }
  }
  & img {
    transition: rotate 1s;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    transform: scale(0.6);
    @include responsive(mobile) {
      width: 100%;
    }
  }
  &-title {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    @include responsive(mobile) {
      font-size: 8px;
    }
  }
  &-description {
    height: 1px;
    width: 80%;
    margin: 0 auto;
    opacity: 0;
    transition: all 1s ease-out;
    &-hide {
      margin: 0 auto;
      width: 80%;
      height: 80px;
      opacity: 1;
      transition: all 1s ease-in;
      @include responsive(mobile) {
        height: 100%;
      }
    }
  }
  &-text {
    text-align: justify;
    height: 0;
    opacity: 0;
    font-size: 14px;
    transition: all 3s ease-in;
    transition-delay: 6s opacity;
    &-hide {
      padding: 15px;
      background-color: lightgray;
      text-align: justify;
      color: $firstColor;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      height: 100%;
      font-size: 12px;
      transition-delay: opacity 3s;
      transition: opacity 4s ease-in;
      transition-delay: opacity 6s;
    }
  }
}

.container__prez {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: auto;
  width: 85%;
  margin: 0 auto;
  min-height: 100%;
  font-family: "Montserrat";
  &--title {
    text-align: center;
    font-family: "Montserrat";
    font-weight: 700;
    height: 80px;

    & h1 {
      margin: 20px auto;
      justify-self: center;
      font-weight: 400;
      font-size: 36px;
      @include responsive(mobile) {
        font-size: 20px;
      }
    }
  }
  &--model {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-bottom: 50px;
    &-choice {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      min-height: 300px;
      @include responsive(mobile) {
        flex-flow: column-reverse;
        margin: 0 auto;
        width: 80%;
      }
    }
  }

  &--list {
    list-style-type: none;
    text-align: center;
    line-height: 40px;
    width: 200px;
    & button {
      border: none;
      background-color: transparent;
      min-width: 200px;
      cursor: pointer;
    }
    & h2 {
      background-color: $fourthColor;
      @include buttonradius();
      color: white;
      font-family: "Montserrat";
      margin: 0;
      height: 40px;
      font-size: 20px;
      @include responsive(mobile) {
        font-size: 12px;
        height: 20px;
        width: 75px;
        line-height: 20px;
      }
    }
    & p {
      text-decoration: none;
      font-family: "Montserrat";
      color: $thirdColor;
      font-size: 14px;
      margin: 0;
      font-weight: bold;
      text-align: center;
      @include responsive(mobile) {
        font-size: 12px;
        height: 20px;
        width: 75px;
        line-height: 20px;
      }
    }
  }

  &--text {
    width: 48%;
    position: relative;
    background-color: rgba(226, 225, 225, 0.931);
    text-align: justify;
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 14px;
    color: rgb(57, 54, 54);
    padding: 15px;
    min-height: 300px;
    @include responsive(mobile) {
      font-size: 10px;
      width: 100%;
      min-height: auto;
      margin-bottom: 30px;
    }
  }
  &--image {
    max-width: 48%;
    display: flex;
    flex-flow: column;
    position: relative;
    @include responsive(mobile) {
      max-width: 100%;
    }
    &-main {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      animation: 1.5s ease-in 1s reverse both running imgslide;
    }
    &--gallery {
      position: absolute;
      width: 100%;
      height: 100%;

      font-weight: 500;
      font-family: "Montserrat";
      font-size: 30px;
      text-align: center;
    }

    &--btn {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      &:focus {
        .container__prez--image-main {
          animation: 3s ease-in 1s reverse both running imgslide;
        }
      }

      &-previous,
      &-next {
        cursor: pointer;
        border: none;
        background: transparent;
        font-size: 50px;

        color: grey;

        &:focus {
          transition-duration: 0.5s;
          color: $thirdColor;
        }
      }
    }
    &--indexpage {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-flow: row;
      top: 85%;
      width: 20%;
      margin-left: 40%;
      margin-right: 40%;
      @include buttonradius(10px);
      background-color: transparentize(
        $color: rgb(244, 244, 247),
        $amount: 0.3
      );

      font-size: 30px;
      color: $fifthColor;
    }
  }
}
.carousel-root {
  @include responsive(mobile) {
    width: 100%;
  }
}
.container__feat {
  display: flex;
  flex-flow: column;
  margin-bottom: 100px;
  background-color: $fifthColor;
  margin-top: 40px;
  height: auto;
  font-size: 16px;
  line-height: 40px;
  padding-left: 30px;
  padding-bottom: 30px;
  @include responsive(mobile) {
    margin-bottom: 50px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  &--button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  &--title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    line-height: 50px;
    height: 50px;
  }
  &--h3 {
    margin: 0;
    font-size: 16px;
    color: white;
    @include responsive(mobile) {
      font-size: 14px;
    }
  }
  & i {
    height: 50px;
    line-height: 50px;
    margin-right: 50px;
    font-size: 30px;
    color: white;
    @include responsive(mobile) {
      height: 20px;
      font-size: 15px;
    }
  }
  &--list {
    margin-left: 100px;
    margin-bottom: 5px;
    @include responsive(mobile) {
      margin-left: 30px;
    }
    & li {
      list-style-type: none;
      height: 20px;
      font-size: 16px;
      @include responsive(mobile) {
        font-size: 8px;
        height: 15px;
        line-height: 10px;
      }
    }
  }
}
