.mainHome__text {
  width: 100%;
  height: auto;
  &--corps {
    display: flex;
    flex-flow: column;
    margin: auto;
    width: 80%;
    & h2 {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 32px;
      margin-top: 100px;
      @include responsive(mobile) {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
}
h1 {
  font-family: "Montserrat";
  font-size: 40px;
  @include responsive(mobile) {
    font-size: 20px;
  }
}

.container__description,
.container__product {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  @include responsive(mobile) {
    padding: 15px;
  }
  & p {
    font-family: "Montserrat";
    font-size: 20px;
    text-align: justify;
    @include responsive(mobile) {
      font-size: 14px;
    }
  }
}

.container__product--gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: auto;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  @include responsive(mobile) {
    margin: 5px;
  }
}

.gallery__card {
  width: 200px;
  height: 225px;
  background-color: $secondColor;
  display: flex;
  flex-flow: column;
  padding-top: 20px;
  margin-bottom: 40px;
  @include buttonradius(15px);
  @include responsive(mobile) {
    width: 400px;
    height: 110px;
    padding-top: 10px;
    flex-flow: row nowrap;
    margin-bottom: 30px;
  }
  & a {
    @include responsive(mobile) {
      display: flex;
      flex-flow: row;
    }
  }

  &--img {
    min-height: 120px;
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
    text-align: center;
    color: white;
    background-size: contain;
    @include responsive(mobile) {
      min-height: 0px;
      width: 85%;
      max-width: 120px;
      height: 90%;
      text-align: left;
      margin-left: 5px;
      margin-right: 0;
    }
  }
  & h3 {
    margin-top: 5px;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: white;
    padding-left: 10px;
    @include responsive(mobile) {
      font-size: 10px;
      width: 40%;
      max-width: 150px;
      padding: 0;
      padding-right: 5px;
    }
  }
}
.article__wellfare-resume {
  position: relative;
  background: transparent;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
  @include responsive(mobile) {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  & p {
    font-family: "Montserrat";
    font-size: 20px;
    text-align: justify;
    @include responsive(mobile) {
      font-size: 14px;
    }
  }
}
