@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400;1,700&display=swap");
@keyframes imgslide {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
* {
  box-sizing: border-box;
}

.App html,
body,
#root {
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0px;
  font-family: "Montserrat";
}

html {
  scroll-behavior: auto;
}
@media screen and (max-width: 980px) {
  html {
    width: 100%;
    height: 100%;
  }
}

body {
  background-color: rgba(23, 23, 72, 0);
  scroll-margin-top: 0;
}

.body {
  display: block;
  height: 100%;
  min-height: 100%;
  padding-bottom: 30px;
}
@media screen and (max-width: 980px) {
  .body {
    width: 100%;
    height: 100%;
  }
}

a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}

.mainHome,
.mainProduct,
.mainContact {
  position: relative;
  top: 150px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .mainHome,
  .mainProduct,
  .mainContact {
    top: 100px;
  }
}

.mainHome__text {
  background-image: url(/src/assets/Fond.webp);
  background-size: 100%;
  background-position-y: 0%;
}
@media screen and (max-width: 980px) {
  .mainHome__text {
    background-size: 100%;
  }
}

.header {
  position: fixed;
  z-index: 1;
  top: 0px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  background-color: rgba(183, 204, 242, 0.8);
  transition: all 1000ms;
}
@media screen and (max-width: 980px) {
  .header {
    background-color: rgba(183, 204, 242, 0.5);
    height: 100px;
  }
}
.header:hover {
  background-color: #b7ccf2;
  transition: all 300ms;
}
.header__logo {
  margin-top: 40px;
  margin-left: 50px;
  width: 200px;
  height: 75px;
}
@media screen and (max-width: 980px) {
  .header__logo {
    width: 140px;
    height: 60px;
    margin-left: 15px;
    margin-top: 20px;
  }
}
.header__nav {
  min-width: 400px;
  margin-right: 100px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
@media screen and (max-width: 980px) {
  .header__nav {
    min-width: 100px;
    flex-flow: column;
    justify-content: flex-start;
  }
}
.header__nav--menu {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  list-style: none;
}
@media screen and (max-width: 980px) {
  .header__nav--menu {
    flex-flow: column;
  }
}
.header__nav li {
  min-width: 100px;
  padding-top: 60px;
  line-height: 100px;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .header__nav li {
    min-width: 50px;
    padding-top: 10px;
    line-height: 30px;
  }
}
.header__nav a {
  text-decoration: none;
  color: #0e4194;
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
}
@media screen and (max-width: 980px) {
  .header__nav a {
    font-size: 10px;
  }
}
.header__nav p {
  height: 50px;
}
.header li:hover {
  cursor: pointer;
}

.footer {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: #0e4194;
  height: 200px;
  padding-bottom: 50px;
  color: white;
}
@media screen and (max-width: 980px) {
  .footer {
    height: 150px;
    padding-bottom: 0;
  }
}
.footer__container {
  width: 40%;
  margin-top: auto;
  margin-bottom: auto;
}
@media screen and (max-width: 980px) {
  .footer__container {
    width: 20%;
    height: 100px;
  }
}
.footer__logo {
  position: relative;
  left: 50px;
  width: 200px;
}
@media screen and (max-width: 980px) {
  .footer__logo {
    width: 100px;
    left: 15px;
  }
}
.footer__adress {
  width: 30%;
  display: flex;
  flex-flow: column;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 10px;
  margin-top: auto;
  margin-bottom: auto;
  border-left: 1px solid white;
  padding-left: 16px;
}
@media screen and (max-width: 980px) {
  .footer__adress {
    font-size: 8px;
    margin: 0;
    height: 130px;
    width: 35%;
    padding-top: 20px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 980px) {
  .footer__adress p {
    margin-top: 5;
  }
}
.footer__adress--contact {
  display: flex;
  color: white;
  flex-flow: row;
}
.footer__adress--contact i {
  width: 50px;
  color: white;
  line-height: 30px;
}
@media screen and (max-width: 980px) {
  .footer__adress--contact i {
    width: 15%;
  }
}
.footer__adress--mail {
  width: 50px;
  line-height: 30px;
  color: white;
}
.footer__network {
  width: 30%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}
.footer__network a {
  color: white;
}
.footer__network :hover {
  cursor: pointer;
}
.footer__network i {
  width: 50%;
  padding-top: 10px;
  margin: 0 auto;
}
.footer__network p {
  font-family: "Montserrat";
  font-size: 20px;
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 980px) {
  .footer__network p {
    font-size: 8px;
  }
}

.imageGallery {
  position: relative;
  width: 100%;
  min-height: 500px;
  background-image: url(/src/assets/IMG_8450.webp);
  background-size: cover;
  background-position-y: 65%;
}
@media screen and (max-width: 980px) {
  .imageGallery {
    min-height: 150px;
  }
}
@media screen and (max-width: 980px) {
  .imageGallery {
    width: 100%;
  }
}

.carousel {
  width: 100%;
}
.carousel-status {
  visibility: hidden;
}
.carousel ul {
  align-items: center;
  margin: 0 auto;
}

.thumbs {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: auto;
}

.control-arrow {
  font-size: 60px;
}

.mainHome__text {
  width: 100%;
  height: auto;
}
.mainHome__text--corps {
  display: flex;
  flex-flow: column;
  margin: auto;
  width: 80%;
}
.mainHome__text--corps h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  margin-top: 100px;
}
@media screen and (max-width: 980px) {
  .mainHome__text--corps h2 {
    font-size: 16px;
    margin-top: 15px;
  }
}

h1 {
  font-family: "Montserrat";
  font-size: 40px;
}
@media screen and (max-width: 980px) {
  h1 {
    font-size: 20px;
  }
}

.container__description,
.container__product {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 980px) {
  .container__description,
  .container__product {
    padding: 15px;
  }
}
.container__description p,
.container__product p {
  font-family: "Montserrat";
  font-size: 20px;
  text-align: justify;
}
@media screen and (max-width: 980px) {
  .container__description p,
  .container__product p {
    font-size: 14px;
  }
}

.container__product--gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: auto;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 980px) {
  .container__product--gallery {
    margin: 5px;
  }
}

.gallery__card {
  width: 200px;
  height: 225px;
  background-color: #0e4194;
  display: flex;
  flex-flow: column;
  padding-top: 20px;
  margin-bottom: 40px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
@media screen and (max-width: 980px) {
  .gallery__card {
    width: 400px;
    height: 110px;
    padding-top: 10px;
    flex-flow: row nowrap;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 980px) {
  .gallery__card a {
    display: flex;
    flex-flow: row;
  }
}
.gallery__card--img {
  min-height: 120px;
  width: 95%;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  color: white;
  background-size: contain;
}
@media screen and (max-width: 980px) {
  .gallery__card--img {
    min-height: 0px;
    width: 85%;
    max-width: 120px;
    height: 90%;
    text-align: left;
    margin-left: 5px;
    margin-right: 0;
  }
}
.gallery__card h3 {
  margin-top: 5px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: white;
  padding-left: 10px;
}
@media screen and (max-width: 980px) {
  .gallery__card h3 {
    font-size: 10px;
    width: 40%;
    max-width: 150px;
    padding: 0;
    padding-right: 5px;
  }
}

.article__wellfare-resume {
  position: relative;
  background: transparent;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
}
@media screen and (max-width: 980px) {
  .article__wellfare-resume {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.article__wellfare-resume p {
  font-family: "Montserrat";
  font-size: 20px;
  text-align: justify;
}
@media screen and (max-width: 980px) {
  .article__wellfare-resume p {
    font-size: 14px;
  }
}

.container__head {
  display: block;
  height: auto;
  min-height: 85%;
}

.avantage_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
}

.avantage-card {
  display: flex;
  flex-flow: column;
  width: 20%;
  height: 160px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.avantage-card_main {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.avantage h2,
.avantage h3 {
  text-align: center;
}
@media screen and (max-width: 980px) {
  .avantage h2 {
    font-size: 16px;
  }
}
.avantage-background-icone {
  display: flex;
  border-top: solid 1px #149ac0;
  border-bottom: solid 1px #215ca8;
  width: 60px;
  height: 60px;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  margin: 0 auto;
  align-items: center;
  transition: rotate 1s;
}
@media screen and (max-width: 980px) {
  .avantage-background-icone {
    width: 30px;
    height: 30px;
  }
}
.avantage-background-icone:hover {
  rotate: 180deg;
}
.avantage-background-icone:hover img,
.avantage-background-icone :focus {
  rotate: -180deg;
}
.avantage img {
  transition: rotate 1s;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  transform: scale(0.6);
}
@media screen and (max-width: 980px) {
  .avantage img {
    width: 100%;
  }
}
.avantage-title {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
}
@media screen and (max-width: 980px) {
  .avantage-title {
    font-size: 8px;
  }
}
.avantage-description {
  height: 1px;
  width: 80%;
  margin: 0 auto;
  opacity: 0;
  transition: all 1s ease-out;
}
.avantage-description-hide {
  margin: 0 auto;
  width: 80%;
  height: 80px;
  opacity: 1;
  transition: all 1s ease-in;
}
@media screen and (max-width: 980px) {
  .avantage-description-hide {
    height: 100%;
  }
}
.avantage-text {
  text-align: justify;
  height: 0;
  opacity: 0;
  font-size: 14px;
  transition: all 3s ease-in;
  transition-delay: 6s opacity;
}
.avantage-text-hide {
  padding: 15px;
  background-color: lightgray;
  text-align: justify;
  color: #242e60;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 100%;
  font-size: 12px;
  transition-delay: opacity 3s;
  transition: opacity 4s ease-in;
  transition-delay: opacity 6s;
}

.container__prez {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: auto;
  width: 85%;
  margin: 0 auto;
  min-height: 100%;
  font-family: "Montserrat";
}
.container__prez--title {
  text-align: center;
  font-family: "Montserrat";
  font-weight: 700;
  height: 80px;
}
.container__prez--title h1 {
  margin: 20px auto;
  justify-self: center;
  font-weight: 400;
  font-size: 36px;
}
@media screen and (max-width: 980px) {
  .container__prez--title h1 {
    font-size: 20px;
  }
}
.container__prez--model {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 50px;
}
.container__prez--model-choice {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 300px;
}
@media screen and (max-width: 980px) {
  .container__prez--model-choice {
    flex-flow: column-reverse;
    margin: 0 auto;
    width: 80%;
  }
}
.container__prez--list {
  list-style-type: none;
  text-align: center;
  line-height: 40px;
  width: 200px;
}
.container__prez--list button {
  border: none;
  background-color: transparent;
  min-width: 200px;
  cursor: pointer;
}
.container__prez--list h2 {
  background-color: #0a74bb;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-family: "Montserrat";
  margin: 0;
  height: 40px;
  font-size: 20px;
}
@media screen and (max-width: 980px) {
  .container__prez--list h2 {
    font-size: 12px;
    height: 20px;
    width: 75px;
    line-height: 20px;
  }
}
.container__prez--list p {
  text-decoration: none;
  font-family: "Montserrat";
  color: #215ca8;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 980px) {
  .container__prez--list p {
    font-size: 12px;
    height: 20px;
    width: 75px;
    line-height: 20px;
  }
}
.container__prez--text {
  width: 48%;
  position: relative;
  background-color: rgba(226, 225, 225, 0.931);
  text-align: justify;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 14px;
  color: rgb(57, 54, 54);
  padding: 15px;
  min-height: 300px;
}
@media screen and (max-width: 980px) {
  .container__prez--text {
    font-size: 10px;
    width: 100%;
    min-height: auto;
    margin-bottom: 30px;
  }
}
.container__prez--image {
  max-width: 48%;
  display: flex;
  flex-flow: column;
  position: relative;
}
@media screen and (max-width: 980px) {
  .container__prez--image {
    max-width: 100%;
  }
}
.container__prez--image-main {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  animation: 1.5s ease-in 1s reverse both running imgslide;
}
.container__prez--image--gallery {
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 30px;
  text-align: center;
}
.container__prez--image--btn {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.container__prez--image--btn:focus .container__prez--image-main {
  animation: 3s ease-in 1s reverse both running imgslide;
}
.container__prez--image--btn-previous, .container__prez--image--btn-next {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 50px;
  color: grey;
}
.container__prez--image--btn-previous:focus, .container__prez--image--btn-next:focus {
  transition-duration: 0.5s;
  color: #215ca8;
}
.container__prez--image--indexpage {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-flow: row;
  top: 85%;
  width: 20%;
  margin-left: 40%;
  margin-right: 40%;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(244, 244, 247, 0.7);
  font-size: 30px;
  color: #149ac0;
}

@media screen and (max-width: 980px) {
  .carousel-root {
    width: 100%;
  }
}

.container__feat {
  display: flex;
  flex-flow: column;
  margin-bottom: 100px;
  background-color: #149ac0;
  margin-top: 40px;
  height: auto;
  font-size: 16px;
  line-height: 40px;
  padding-left: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 980px) {
  .container__feat {
    margin-bottom: 50px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}
.container__feat--button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.container__feat--title {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  line-height: 50px;
  height: 50px;
}
.container__feat--h3 {
  margin: 0;
  font-size: 16px;
  color: white;
}
@media screen and (max-width: 980px) {
  .container__feat--h3 {
    font-size: 14px;
  }
}
.container__feat i {
  height: 50px;
  line-height: 50px;
  margin-right: 50px;
  font-size: 30px;
  color: white;
}
@media screen and (max-width: 980px) {
  .container__feat i {
    height: 20px;
    font-size: 15px;
  }
}
.container__feat--list {
  margin-left: 100px;
  margin-bottom: 5px;
}
@media screen and (max-width: 980px) {
  .container__feat--list {
    margin-left: 30px;
  }
}
.container__feat--list li {
  list-style-type: none;
  height: 20px;
  font-size: 16px;
}
@media screen and (max-width: 980px) {
  .container__feat--list li {
    font-size: 8px;
    height: 15px;
    line-height: 10px;
  }
}

.article__wellfare {
  background-color: transparent;
  width: 70%;
  margin: 0 auto;
}
.article__wellfare h2 {
  font-size: 30px;
  justify-content: center;
}
.article__wellfare p {
  font-family: "Montserrat";
  font-size: 14px;
  text-align: justify;
}
.article__wellfare br {
  margin-bottom: 10px;
}

.container__form {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 50px;
  width: 90%;
  justify-content: flex-end;
  margin: 0 auto;
}

form {
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  justify-content: space-between;
}
@media screen and (max-width: 980px) {
  form {
    margin: 10px;
  }
}
form label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  margin-right: 100px;
  font-size: 14px;
}
@media screen and (max-width: 980px) {
  form label {
    font-size: 10px;
    margin-right: 10px;
  }
}
form label textarea {
  height: 150px;
  padding-left: 20px;
  width: 80%;
  font-size: 26px;
  font-family: "Montserrat";
  color: white;
  background-color: #0a74bb;
  border-radius: 10px;
}
@media screen and (max-width: 980px) {
  form label textarea {
    font-size: 12px;
    width: 70%;
  }
}
form button {
  width: 150px;
  height: 40px;
  font-size: 16px;
  margin-right: 0;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}
@media screen and (max-width: 980px) {
  form button {
    width: 100px;
    font-size: 14px;
    height: 24px;
  }
}
form p {
  font-family: "Montserrat";
  text-align: right;
  font-weight: 500;
  font-size: 24px;
  padding-right: 30px;
  margin: 0 auto;
  border-radius: 10px;
  line-height: 40px;
  height: 40px;
  word-wrap: break-word;
  width: 15%;
  color: white;
  background-color: #0e4194;
}
form input {
  border-style: none;
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
  padding-left: 20px;
  background-color: #0a74bb;
  border-radius: 10px;
  width: 80%;
  height: 28px;
}
@media screen and (max-width: 980px) {
  form input {
    font-size: 10px;
    width: 70%;
    height: 20px;
  }
}

.form__checkbox-main {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 30px;
}
.form__checkbox-title {
  margin: 0;
}
.form__checkbox-choice {
  display: flex;
  width: 80%;
}
@media screen and (max-width: 980px) {
  .form__checkbox-choice {
    width: 70%;
    flex-flow: column;
  }
}
.form__checkbox-label {
  width: 85%;
  margin: 0 auto;
  justify-content: flex-start;
  line-height: 20px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  .form__checkbox-label {
    font-size: 10px;
    margin: 0;
    width: 200px;
    justify-content: space-between;
  }
}
.form__checkbox-input {
  margin: 0;
  margin-left: 10px;
  height: 15px;
  width: 15px;
}

.Form__submit {
  width: 200px;
  font-weight: 600;
  background-color: #149ac0;
}

.error {
  max-width: 100%;
  height: 800px;
  top: 160px;
  margin: 0 auto;
  font-family: "Montserrat";
  font-style: normal;
  background: #0a74bb;
  color: #242e60;
  text-align: center;
}
.error__number {
  height: 263px;
  top: 277px;
  font-weight: 700;
  font-size: 288px;
  line-height: 411px;
}
@media screen and (max-width: 980px) {
  .error__number {
    height: 99px;
    top: 262px;
  }
}
.error__text {
  height: 51px;
  top: 606px;
  font-weight: 500;
  font-size: 36px;
  line-height: 51px;
}
@media screen and (max-width: 980px) {
  .error__text {
    text-align: center;
    width: 75%;
    top: 372px;
    margin: 0 auto;
  }
}
.error__link {
  top: 839px;
  height: 26px;
  font-weight: 500;
  font-size: 18px;
  line-height: 51px;
}
.error__link a {
  color: white;
}

