$firstColor: #242e60;
$secondColor: #0e4194;
$thirdColor: #215ca8;
$fourthColor: #0a74bb;
$fifthColor: #149ac0;

$breakpoints: (
  mobile: 980px,
  tablet: 1120px,
  desktop: 1400px,
);
