.container__form {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 50px;
  width: 90%;
  justify-content: flex-end;
  margin: 0 auto;
}
form {
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  justify-content: space-between;
  @include responsive(mobile) {
    margin: 10px;
  }
  & label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-right: 100px;
    font-size: 14px;
    @include responsive(mobile) {
      font-size: 10px;
      margin-right: 10px;
    }
    & textarea {
      height: 150px;
      padding-left: 20px;
      width: 80%;
      font-size: 26px;
      font-family: "Montserrat";
      color: white;
      background-color: $fourthColor;
      border-radius: 10px;
      @include responsive(mobile) {
        font-size: 12px;
        width: 70%;
      }
    }
  }
  & button {
    width: 150px;
    height: 40px;
    font-size: 16px;
    margin-right: 0;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 80px;
    @include responsive(mobile) {
      width: 100px;
      font-size: 14px;
      height: 24px;
    }
  }
  & p {
    font-family: "Montserrat";
    text-align: right;
    font-weight: 500;
    font-size: 24px;
    padding-right: 30px;
    margin: 0 auto;
    border-radius: 10px;
    line-height: 40px;
    height: 40px;
    word-wrap: break-word;
    width: 15%;
    color: white;
    background-color: $secondColor;
  }
  & input {
    border-style: none;
    color: white;
    font-family: "Montserrat";
    font-size: 16px;
    padding-left: 20px;
    background-color: $fourthColor;
    border-radius: 10px;
    width: 80%;
    height: 28px;
    @include responsive(mobile) {
      font-size: 10px;
      width: 70%;
      height: 20px;
    }
  }
}

.form__checkbox {
  &-main {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  &-title {
    margin: 0;
  }

  &-choice {
    display: flex;
    width: 80%;
    @include responsive(mobile) {
      width: 70%;
      flex-flow: column;
    }
  }
  &-label {
    width: 85%;
    margin: 0 auto;
    justify-content: flex-start;
    line-height: 20px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    @include responsive(mobile) {
      font-size: 10px;
      margin: 0;
      width: 200px;
      justify-content: space-between;
    }
  }
  &-input {
    margin: 0;
    margin-left: 10px;
    height: 15px;
    width: 15px;
  }
}

.Form__submit {
  width: 200px;
  font-weight: 600;
  background-color: $fifthColor;
}
