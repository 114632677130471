.carousel {
  width: 100%;
  &-status {
    visibility: hidden;
  }
  & ul {
    align-items: center;
    margin: 0 auto;
  }
}
.thumbs {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: auto;
}
.control-arrow {
  font-size: 60px;
}
